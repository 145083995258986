import { Suspense, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Outlet } from 'react-router-dom';
import { MainLayout } from './components/layout/mainLayout';

export const App = () => {
  useEffect(() => {
    ReactGA.initialize('G-ZE65WXLLL9');
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      path: window.location.pathname + window.location.search
    });
  }, [window.location]);

  return (
    <MainLayout>
      <Suspense fallback={<div className='h-full w-full flex items-center justify-center'>Loading....</div>}>
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};
