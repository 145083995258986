import { FunnelConstants } from '../../features/funnel/FunnelConstants';
import { RemovedItem } from '../../features/funnel/steps/custom/Quote/QuoteStep';
import { CalculatorResponseModel } from './Models/CalculatorResponseModel';
import { CleaningFrequency } from './Models/CleaningFrequency';
import { calculatePrice } from './calculator';

export const getQuotesForCleaningType = async (formModel: any, removedItems: RemovedItem[]) => {
  const toReturn: Array<CalculatorResponseModel> = [];
  const cleaningQuote = formModel.Contact.CleaningQuote;
  const serviceInterval = cleaningQuote.ServiceInterval;
  switch (serviceInterval) {
    case FunnelConstants.serviceIntervals.DeepClean:
      toReturn.push(await calculatePrice(formModel, CleaningFrequency.Initial, removedItems));
      toReturn.push(await calculatePrice(formModel, CleaningFrequency.Biweekly, removedItems));
      toReturn.push(await calculatePrice(formModel, CleaningFrequency.Monthly, removedItems));
      break;
    case FunnelConstants.serviceIntervals.MoveIn:
      toReturn.push(await calculatePrice(formModel, CleaningFrequency.MoveInMoveOut, removedItems));
      toReturn.push(await calculatePrice(formModel, CleaningFrequency.Biweekly, removedItems));
      toReturn.push(await calculatePrice(formModel, CleaningFrequency.Monthly, removedItems));
      break;
    case FunnelConstants.serviceIntervals.MoveOut:
      toReturn.push(await calculatePrice(formModel, CleaningFrequency.MoveInMoveOut, removedItems));
      toReturn.push(await calculatePrice(formModel, CleaningFrequency.Biweekly, removedItems));
      toReturn.push(await calculatePrice(formModel, CleaningFrequency.Monthly, removedItems));
      break;
    case FunnelConstants.serviceIntervals.Recurring:
      toReturn.push(await calculatePrice(formModel, CleaningFrequency.Biweekly, removedItems));
      toReturn.push(await calculatePrice(formModel, CleaningFrequency.Initial, removedItems));
      toReturn.push(await calculatePrice(formModel, CleaningFrequency.Monthly, removedItems));

      break;
  }
  return toReturn;
};
