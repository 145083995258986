import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import { CalculatorResponseModel } from '../../../../../lib/Calculator/Models/CalculatorResponseModel';
import { CleaningFrequency } from '../../../../../lib/Calculator/Models/CleaningFrequency';
import { QuoteAddonLine } from './QuoteAddonLine';
import { QuoteBasePrice } from './QuoteBasePrice';
import { QuoteTotalLine } from './QuoteTotalLine';

type QuoteDetails = {
  quote: CalculatorResponseModel;
  color: string;
  collapseDefaultState: boolean;
  canRemoveAddons: boolean;
  onRemoveAddon: (formKey: string, cleaningFrequency: CleaningFrequency) => void;
};

export const QuoteDetails = ({ quote, color, collapseDefaultState, canRemoveAddons, onRemoveAddon }: QuoteDetails) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(collapseDefaultState);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  const primaryLineItem = quote.lineItems.find((r) => {
    return r.isPrimaryItem;
  });

  const addonItems = quote.lineItems.filter((r) => {
    return !r.isPrimaryItem;
  });

  const colorString = `${color}.100`;

  const getExpandedView = () => {
    return (
      <Box marginTop={'10px'} padding={{ base: 0, lg: '10px 0 0px 0px' }} borderRadius={'4px'} border={'1px'} backgroundColor={'white'} borderColor={colorString}>
        <QuoteBasePrice isCollapsed={isCollapsed} onCollapseToggle={toggleCollapsed} price={primaryLineItem.amount} productName={'Starting Price'} />
        {addonItems.map((addon, index) => {
          const lineColor = index % 2 == 1 ? 'white' : color;
          return (
            <QuoteAddonLine
              onRemoveAddons={(formKey: string) => {
                onRemoveAddon(formKey, quote.cleaningFrequency);
              }}
              formKey={addon.formKey}
              showQuantity={addon.showQuantity}
              key={index}
              name={addon.item}
              quantity={addon.quantity}
              price={addon.amount}
              color={lineColor}
              canRemove={canRemoveAddons}
            />
          );
        })}

        <QuoteTotalLine isCollapsed={isCollapsed} onToggleCollapse={toggleCollapsed} heading={`${quote.productName}`} priceRange={quote.totalCost} essentialsRange={quote.essentialsCost} color={color} />
      </Box>
    );
  };

  return !isCollapsed ? getExpandedView() : <QuoteTotalLine isCollapsed={isCollapsed} onToggleCollapse={toggleCollapsed} heading={`${quote.productName}`} priceRange={quote.totalCost} essentialsRange={quote.essentialsCost} color={color} />;
};
